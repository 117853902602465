import {
  PaginationQuery,
  PaginationRequestType,
} from '../../types/paginationRequestType';
import {
  CreatePetition,
  CreatePetitionData,
  PetitionInf,
  PetitionPaymentDetails,
  ViewPetition,
} from '../../types/petitions';
import { PETITIONS } from '../endpoints';
import request from '../request';

export const getPetitionsList = (
  query: { status?: string } & PaginationQuery,
) =>
  request<PaginationRequestType<PetitionInf>>(PETITIONS.LIST, {
    method: 'GET',
    params: query,
  });

export const getPetitionsListResolved = (
  query: { status?: string } & PaginationQuery,
) =>
  request<PaginationRequestType<PetitionInf>>(PETITIONS.LIST_RESOLVED, {
    method: 'GET',
    params: query,
  });

export const getPetitionCreationData = () =>
  request<CreatePetitionData>(PETITIONS.FORM, { method: 'GET' });

export const createPetition = (data: CreatePetition) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request(PETITIONS.CREATE, { method: 'POST', data: formData });
};

export const viewPetition = (petitionId: number) => {
  return request<ViewPetition>(PETITIONS.VIEW(petitionId), { method: 'GET' });
};

export const getPetitionPaymentDetails = (petitionId: number) =>
  request<PetitionPaymentDetails>(PETITIONS.PAYMENT_DETAILS(petitionId), {
    method: 'GET',
  });

export const getPetitionPaymentLink = (petitionId: number) =>
  request<string>(PETITIONS.PAYMENT_LINK(petitionId), {
    method: 'POST',
  });

export const getPetitionVoucherDownloadLink = (petitionId: number) =>
  request<string>(PETITIONS.VOUCHER_LINK(petitionId), {
    method: 'POST',
  });
