import { Row, Col, Container } from 'reactstrap';
import { PetitionPaymentDetails } from '../../../../types/petitions';

interface VoucherBoxPetitionProps {
  items: PetitionPaymentDetails['voucherBoxItems'];
}

export default function VoucherBoxPetition({ items }: VoucherBoxPetitionProps) {
  return (
    <Container className="rounded p-3" style={{ backgroundColor: '#F4F4F4' }}>
      {items.map((item) => (
        <Row>
          <Col xs={6}>
            <span className="fw-16 text-left w-100">{`${item.label}:`}</span>
          </Col>
          <Col xs={6}>
            <span className="fw-16 fw-700 text-primary text-left w-100">
              {`${item.value}`}
            </span>
          </Col>
        </Row>
      ))}
    </Container>
  );
}
