import { Button, Icon } from '@octano/global-ui';
import { useCallback } from 'react';
import { Card, Col, Row } from 'reactstrap';

import { ViewPetition } from '../../../types/petitions';

export interface PetitionFileDownloadProps {
  file?: ViewPetition['doc'];
  text: string;
  label: string;
  voucher?: ViewPetition['voucher'];
  voucherLabel?: string;
}
export default function PetitionFileDownload({
  file,
  label,
  text,
  voucher,
  voucherLabel,
}: PetitionFileDownloadProps) {
  const onDownload = useCallback(() => {
    if (file) {
      const link = document.createElement('a');
      link.download = file.name;
      link.href = file.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [file]);
  const onDownloadVoucher = useCallback(() => {
    if (voucher) {
      const link = document.createElement('a');
      link.download = voucher.name;
      link.href = voucher.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [voucher]);
  return (
    <Card>
      <Row className="mb-2">
        <Col xs={12} sm={voucher ? 6 : 12}>
          <label className="g-file-input-label text-left">{label}</label>
        </Col>
        {voucher && (
          <Col xs={12} sm={6}>
            <label className="g-file-input-label text-left">
              {voucherLabel}
            </label>
          </Col>
        )}
      </Row>
      <Row>
        {voucher ? (
          <>
            <Col xs={12} sm={3}>
              <div className="g-file-input-container">
                {!!file && (
                  <>
                    <span>
                      <Icon name="file_img" size="25px" color="primary" />
                    </span>
                    <span className="g-file-input-name">{file.name}</span>
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} sm={3}>
              <Button
                color="primary"
                outlined
                size="md"
                disabled={!file}
                onClick={onDownload}
                icon="download"
              />
            </Col>
            <Col xs={12} sm={3}>
              <div className="g-file-input-container">
                {!!voucher && (
                  <>
                    <span>
                      <Icon name="file_img" size="25px" color="primary" />
                    </span>
                    <span className="g-file-input-name">{voucher.name}</span>
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} sm={3}>
              <Button
                color="primary"
                outlined
                size="md"
                disabled={!voucher}
                onClick={onDownloadVoucher}
                icon="download"
              />
            </Col>
          </>
        ) : (
          <>
            <Col xs={12} sm={6}>
              <div className="g-file-input-container">
                {!!file && (
                  <>
                    <span>
                      <Icon name="file_img" size="25px" color="primary" />
                    </span>
                    <span className="g-file-input-name">{file.name}</span>
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <Button
                text={text}
                color="primary"
                outlined
                size="md"
                disabled={!file}
                onClick={onDownload}
                icon="download"
                fullwidth
              />
            </Col>
          </>
        )}
      </Row>
    </Card>
  );
}
