import { Row, Col, Container } from 'reactstrap';
import { Icon, Alert, Button } from '@octano/global-ui';
import { PetitionPaymentDetails } from '../../../../types/petitions';
import { useTranslation } from 'react-i18next';
import VoucherBoxPetition from './VoucherBox';
import { TranslationsKeys } from '../../../../locales/translations';

interface VoucherErrorPetitionProps {
  details: PetitionPaymentDetails;
  loading?: boolean;
  onBackToList?: () => void;
}

export default function VoucherErrorPetition({
  details,
  loading,
  onBackToList,
}: VoucherErrorPetitionProps) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  return (
    <Container style={{ marginTop: 90, marginBottom: 90 }}>
      <Row style={{ maxWidth: 400 }} className="mx-auto">
        <Col xs={12} className="text-center">
          <Icon name="error" color="danger" size={60} />
        </Col>
        <Col xs={12}>
          <h2 className="text-center w-100 fw-500 text-dark fs-20 mt-4 mb-4">
            {t('voucherError.title')}
          </h2>
        </Col>
        <Col xs={12}>
          <Alert icon="error" color="danger" text={t('voucherError.alert')} />
        </Col>
        <Col xs={12} className="mb-4">
          <VoucherBoxPetition items={details?.voucherBoxItems ?? []} />
        </Col>
        {!!onBackToList && (
          <Col xs={12}>
            <Button
              text={t('voucherError.backToList')}
              size="md"
              fullwidth
              outlined
              loading={loading}
              onClick={onBackToList}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}
