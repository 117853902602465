import { Row, Col, Container } from 'reactstrap';
import { Icon, Alert, Button } from '@octano/global-ui';
import { PetitionPaymentDetails } from '../../../../types/petitions';
import { useTranslation } from 'react-i18next';
import VoucherBoxPetition from './VoucherBox';
import { TranslationsKeys } from '../../../../locales/translations';

interface VoucherSuccessPetitionProps {
  details: PetitionPaymentDetails;
  loading?: boolean;
  onBackToList?: () => void;
  onDownload?: () => void;
}

export default function VoucherSuccessPetition({
  details,
  loading,
  onBackToList,
  onDownload,
}: VoucherSuccessPetitionProps) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  return (
    <Container style={{ marginTop: 90, marginBottom: 90 }}>
      <Row style={{ maxWidth: 400 }} className="mx-auto">
        <Col xs={12} className="text-center">
          <Icon name="success" color="primary" size={60} />
        </Col>
        <Col xs={12}>
          <h2 className="text-center w-100 fw-500 text-dark fs-20 mt-4 mb-4">
            {t('voucherSuccess.title')}
          </h2>
        </Col>
        <Col xs={12}>
          <Alert icon="information" text={t('voucherSuccess.alert')} />
        </Col>
        <Col xs={12} className="mb-4">
          <VoucherBoxPetition items={details?.voucherBoxItems ?? []} />
        </Col>
        {onDownload && (
          <Col xs={12} className="mb-2">
            <Button
              text={t('voucherSuccess.downloadVoucher')}
              icon="download"
              size="md"
              fullwidth
              loading={loading}
              onClick={onDownload}
            />
          </Col>
        )}
        {!!onBackToList && (
          <Col xs={12}>
            <Button
              text={t('voucherSuccess.backToList')}
              size="md"
              fullwidth
              outlined
              loading={loading}
              onClick={onBackToList}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}
