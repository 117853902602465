import { Button, Modal } from '@octano/global-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { getPetitionCreationData } from '../../../../api/requests/petitions';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { TranslationsKeys } from '../../../../locales/translations';
import { CreatePetitionData } from '../../../../types/petitions';
import CreatePetitionForm from './CreatePetitionForm';

export interface CreatePetitionBtnModalProps {
  textBtn: string;
}
export default function CreatePetitionBtnModal({
  textBtn,
}: CreatePetitionBtnModalProps) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const [isOpen, setIsOpen] = useState(false);
  const {
    loading,
    setLoading,
    setErrorLoading,
    errorLoading,
  } = useLoadingState();
  const [creationData, setCreationData] = useState<CreatePetitionData>({
    types: [],
    cellPhone: '',
    email: '',
    replaceEvaluationGrades: [],
    replaceCourseGrades: [],
  });
  const getCreationData = useCallback(async () => {
    setLoading(true);
    const response = await getPetitionCreationData();
    if (response.data) {
      setCreationData(response.data);
    } else if (response.error) {
      setErrorLoading(response.error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setCreationData, setLoading]);

  const toggle = useCallback(() => {
    setIsOpen((prevState) => {
      return !prevState;
    });
  }, []);

  const text = useMemo(() => {
    const prefix = 'modal.newPetition';
    return {
      title: t(`${prefix}.title`),
      subtitle: t(`${prefix}.subtitle`),
    };
  }, [t]);

  useEffect(() => {
    getCreationData();
  }, [getCreationData]);
  return (
    <>
      <Button text={textBtn} size="md" className="fw-700" onClick={toggle} />
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <Card className=" mx-4">
          <Row>
            <Col md={12} className="text-center">
              <h6 className="fs-20 fw-400 ">{text.title}</h6>
              <p>{text.subtitle}</p>
            </Col>
            <Col>
              <CreatePetitionForm
                {...creationData}
                close={toggle}
                loading={loading}
                error={errorLoading}
              />
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
}
