import { Button, SelectOptionType, addToast } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { clean } from 'rut.js';

import { saveSustainer } from '../../../../../api/requests/tuitionContinuity';
import ContactSection from '../../../../../components/form/ContactSection';
import { useStepState } from '../../../../../hooks/useStepState';
import { DataOwnSustainer } from '../../../../../types/sustainerTypes';
import PersonalInformation from './PersonalInformation';

export type FieldsSustainerForm = {
  relationship: SelectOptionType | null;
  occupation: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry: SelectOptionType | null;
  ownSustainer: boolean;
  addressStreet: string;
  addressNumber: string;
  addressExtra: string;
  commune: SelectOptionType | null;
  region: SelectOptionType | null;
  phone: string;
  cellphone: string;
  email: string;
  maritalStatus: SelectOptionType | null;
  sexID: SelectOptionType | null;
  nationality: SelectOptionType | null;
};

type SustainerFormProps = {
  defaultValues?: DefaultValues<FieldsSustainerForm>;
  dataOwnSustainer?: DataOwnSustainer;
};

function SustainerForm({
  defaultValues,
  dataOwnSustainer,
}: SustainerFormProps) {
  const prefix = 'tuitionContinuity.steps.sustainer';
  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm<FieldsSustainerForm>({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = methods;
  const { nextStep } = useStepState();
  const ownSustainer = watch('ownSustainer');
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  useEffect(() => {
    if (ownSustainer !== undefined) {
      if (ownSustainer) {
        reset({
          ownSustainer: true,
          relationship: dataOwnSustainer?.relationship ?? null,
          rut: dataOwnSustainer?.rut ?? '',
          passportCountry: dataOwnSustainer?.passportCountry ?? null,
          passportNumber: dataOwnSustainer?.passportNumber ?? '',
          names: dataOwnSustainer?.names ?? '',
          paternalLastName: dataOwnSustainer?.paternalLastName ?? '',
          maternalLastName: dataOwnSustainer?.maternalLastName ?? '',
          nationality: dataOwnSustainer?.nationality ?? null,
          maritalStatus: dataOwnSustainer?.maritalStatus ?? null,
          sexID: dataOwnSustainer?.sexID ?? null,
          addressStreet: dataOwnSustainer?.addressStreet ?? '',
          addressExtra: dataOwnSustainer?.addressExtra ?? '',
          addressNumber: dataOwnSustainer?.addressNumber ?? '',
          region: dataOwnSustainer?.region ?? null,
          commune: dataOwnSustainer?.commune ?? null,
          cellphone: dataOwnSustainer?.cellphone ?? '',
          email: dataOwnSustainer?.email ?? '',
          occupation: defaultValues?.occupation ?? '',
        });
      } else {
        reset({
          ownSustainer: false,
          rut: '',
          relationship: null,
          nationality: null,
          sexID: null,
          maritalStatus: null,
          region: null,
        });
      }
    }
  }, [ownSustainer, reset, dataOwnSustainer, defaultValues]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = useCallback(
    async (values: FieldsSustainerForm) => {
      let saveValues = values;
      if (values.ownSustainer) {
        // Para guardar los valores del alumno como su propio sustantador,
        // se mezcla los values con los datos de dataOwnSustainer
        // ya que al dejar los inputs disabled react hook forms retorna los valores como undefined
        // los datos de contactos son opcionales y la comuna puede ser nula
        if (dataOwnSustainer?.commune === undefined) {
          delete dataOwnSustainer?.commune;
        }
        saveValues = { ...values, ...dataOwnSustainer };
      }
      if (
        saveValues.commune &&
        saveValues.relationship &&
        saveValues.maritalStatus &&
        saveValues.nationality &&
        saveValues.sexID
      ) {
        const { status, error } = await saveSustainer(studyPlanEnrollmentId, {
          type:
            saveValues?.passportNumber && saveValues.passportNumber !== ''
              ? 'passport'
              : 'rut',
          rut: saveValues.rut ? clean(saveValues.rut) : undefined,
          passportCountry:
            saveValues?.passportCountry?.value.toString() ?? undefined,
          passportNumber: saveValues?.passportNumber,
          names: saveValues.names,
          paternalLastName: saveValues.paternalLastName,
          maternalLastName: saveValues.maternalLastName,
          relationshipId: +saveValues.relationship?.value,
          occupation: saveValues.occupation,
          addressStreet: saveValues.addressStreet,
          addressNumber: saveValues.addressNumber,
          addressExtra: saveValues.addressExtra ?? null,
          communeId: +saveValues.commune?.value,
          phone: saveValues.phone ?? null,
          cellPhone: saveValues.cellphone,
          email: saveValues.email,
          maritalStatusId: +saveValues.maritalStatus.value,
          nationalityId: saveValues.nationality.value.toString(),
          sexId: +saveValues?.sexID?.value,
        });

        if (status === 201 && error === false) {
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.saveSuccess`),
          });
          nextStep();
        } else if (error) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.saveError`),
          });
        }
      }
    },
    [dataOwnSustainer, t, nextStep, studyPlanEnrollmentId],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation dataOwnSustainer={dataOwnSustainer} />
        <ContactSection prefix={`${prefix}.contact`} />
        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => history.push('/tuition-process')}
              text={t(`common.actions.cancel`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              type="submit"
              text={t(`common.actions.next`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
}

export default SustainerForm;
