import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { getPetitionsList } from '../../../api/requests/petitions';
import Base from '../../../components/base/Base';
import FetchProvider from '../../../components/contexts/FetchContext';
import { TranslationsKeys } from '../../../locales/translations';
import { PaginationQuery } from '../../../types/paginationRequestType';
import CreatePetitionBtnModal from './parts/CreatePetitionBtnModal';
import PetitionTable from './parts/PetitionTable';

export default function CurrentPetitions() {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const getPetitionList = useCallback(
    (query: PaginationQuery) => getPetitionsList(query),
    [],
  );
  const text = useMemo(() => {
    return {
      addBtn: t(`addBtn`),
    };
  }, [t]);
  return (
    <Base>
      <Card className="pt-4 pb-4">
        <FetchProvider
          defaultQuery={{ items_per_page: 10, page: 0 }}
          request={getPetitionList}
          fetchImmediately
        >
          <Row>
            <Col md={4} xs={12}>
              <CreatePetitionBtnModal textBtn={text.addBtn} />
            </Col>
          </Row>
          <PetitionTable />
        </FetchProvider>
      </Card>
    </Base>
  );
}
