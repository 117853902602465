import { useMemo, useCallback, useState, useEffect } from 'react';
import { addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Container } from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import ButtonBack from '../../../components/button/ButtonBack';

import TopBar from '../../../components/topBar/TopBar';
import { TranslationsKeys } from '../../../locales/translations';
import VoucherSuccessPetition from './parts/VoucherSuccessPetition';
import {
  getPetitionPaymentDetails,
  getPetitionVoucherDownloadLink,
} from '../../../api/requests/petitions';
import {
  PetitionPaymentDetails,
  PetitionPaymentStatus,
} from '../../../types/petitions';
import Loading from '../../../components/info/Loading';
import DisplayError from '../../../components/info/DisplayError';
import VoucherErrorPetition from './parts/VoucherErrorPetition';

export default function VoucherPetition() {
  const history = useHistory();
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const { petitionId } = useParams<{ petitionId: string }>();

  const [details, setDetails] = useState<PetitionPaymentDetails>();

  const [mounted, setMounted] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  const text = useMemo(() => {
    return {
      title: t(`title`),
      subtitle: t(`subtitle`),
      info: t(`info`),
      backToList: t(`backToList`),
    };
  }, [t]);

  const fetchData = useCallback(async () => {
    if (fetching) {
      return;
    }
    setFetching(true);
    const { data } = await getPetitionPaymentDetails(+petitionId);
    setFetching(false);
    setDetails(data ?? undefined);
  }, [petitionId, fetching]);

  const downloadVoucher = useCallback(async () => {
    try {
      if (fetching || downloading) {
        return;
      }
      setDownloading(true);
      const { data, error } = await getPetitionVoucherDownloadLink(+petitionId);
      setDownloading(false);

      if (error || !data) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`voucherError.downloadError`, { id: petitionId }),
        });
        return;
      }
      window.open(data, '_blank')?.focus();
    } catch (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`voucherError.downloadError`, { id: petitionId }),
      });
    }
  }, [petitionId, fetching, downloading, t]);

  const handleBack = useCallback(() => {
    history.replace('/petitions/current');
  }, [history]);

  useEffect(() => {
    if (!mounted) {
      fetchData();
      setMounted(true);
    }
  }, [mounted, fetchData]);

  if (fetching) {
    return (
      <>
        <TopBar title={text.title} />
        <Loading insideCard className="mx-3" />
      </>
    );
  }

  if (!details) {
    return (
      <>
        <TopBar title={text.title} />
        <DisplayError
          insideCard
          className="mx-3"
          body={t('voucherError.notFound', { id: petitionId })}
          textButton={text.backToList}
          retryAction={handleBack}
        />
      </>
    );
  }

  return (
    <>
      <TopBar title={text.title} />
      <Card className="p-4 mx-3">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <ButtonBack text={text.backToList} onClick={handleBack} />
            </Col>
          </Row>
        </Container>
        {details?.paymentStatus === PetitionPaymentStatus.Paid && (
          <VoucherSuccessPetition
            details={details}
            loading={downloading}
            onBackToList={handleBack}
            onDownload={downloadVoucher}
          />
        )}
        {details?.paymentStatus !== PetitionPaymentStatus.Paid && (
          <VoucherErrorPetition
            details={details}
            loading={downloading}
            onBackToList={handleBack}
          />
        )}
      </Card>
    </>
  );
}
