import { Icon } from '@octano/global-ui';
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationsKeys } from '../../../../locales/translations';
import { PetitionInf } from '../../../../types/petitions';
import PetitionStatusBadge from '../../currentPetitions/parts/PetitionStatusBadge';

export default function useColumnResolvedPetitions({
  toggle,
}: {
  toggle: (petition?: PetitionInf) => void;
}) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const prefix = 'table';

  const text = useMemo(
    () => ({
      columns: {
        folio: t(`${prefix}.columns.folio`),
        createdAt: t(`${prefix}.columns.createdAt`),
        studyPlanVersionName: t(`${prefix}.columns.studyPlanVersionName`),
        typeName: t(`${prefix}.columns.typeName`),
        title: t(`${prefix}.columns.title`),
        status: t(`${prefix}.columns.status`),
        view: t(`${prefix}.columns.view`),
      },
    }),
    [t],
  );

  const columns = useMemo<ColumnTable<PetitionInf>[]>(
    () => [
      {
        columnName: 'folio',
        width: '15%',
        headerText: text.columns.folio,
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        columnName: 'createdAt',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.createdAt,
        cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
      },
      {
        columnName: 'studyPlanVersionName',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.studyPlanVersionName,
      },
      {
        columnName: 'typeName',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.typeName,
      },
      {
        columnName: 'title',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.title,
      },
      {
        columnName: 'status',
        width: '130px',
        headerText: text.columns.status,
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: ({ row, value }) => {
          return <PetitionStatusBadge status={value} name={row.folio} />;
        },
      },
      {
        thClassName: 'text-center',
        tdClassName: 'text-center',
        columnName: 'view',
        width: '6%',
        headerText: text.columns.view,
        cellFormat: ({ row }) => {
          return (
            <span onClick={() => toggle(row)} className="cursor-pointer">
              <Icon name="eye" size="25px" />
            </span>
          );
        },
      },
    ],
    [text, toggle],
  );

  return columns;
}
