import { Button, Icon, Modal } from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';
import { useHistory } from 'react-router-dom';
interface Props {}

export interface TuitionNotFoundModalRef {
  openModal: () => void;
  closeModal: () => void;
}

export enum TuitionErrors {
  CURRENT_TUITION_NOT_FOUND = 'CURRENT_TUITION_NOT_FOUND',
}

const TuitionNotFoundModal = forwardRef<TuitionNotFoundModalRef, Props>(
  (_, ref) => {
    const { t } = useTranslation(TranslationsKeys.OLD_TRANSLATIONS);
    const history = useHistory();
    const [modal, setModal] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal() {
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    return (
      <Modal isOpen={modal} toggle={() => null}>
        <Container fluid className="text-center">
          <Icon name="warning" size={45} />
          <p className="h1 text-dark fs-20 mt-5 mb-5">
            {t('finishCourseRegistration.tuitionNotFoundModal.title')}
          </p>

          <p className="fs-16 lh-30 mt-3 mb-5">
            {t('finishCourseRegistration.tuitionNotFoundModal.body')}
          </p>

          <div className="d-flex justify-content-around" style={{ gap: 10 }}>
            <div className="flex-fill">
              <Button
                className="w-100"
                text={t('finishCourseRegistration.tuitionNotFoundModal.action')}
                onClick={() => {
                  setModal(false);
                  history.push('/tuition-continuity');
                }}
              />
            </div>
          </div>
        </Container>
      </Modal>
    );
  },
);

export default TuitionNotFoundModal;
