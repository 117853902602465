import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import TabControl from '../../components/tabs/TabControl';
import TopBar from '../../components/topBar/TopBar';
import { PathsLayouts } from '../../config/routes';
import { TranslationsKeys } from '../../locales/translations';
import ResolvedPetitions from './resolvedPetitions';
import CurrentPetitions from './currentPetitions';

export default function Petitions() {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const text = useMemo(() => {
    return {
      title: t(`title`),
      subtitle: t(`subtitle`),
      info: t(`info`),
    };
  }, [t]);

  const tabs = useMemo(
    () => [
      {
        name: 'current',
        literalName: t(`tabs.current`),
        content: <CurrentPetitions />,
      },
      {
        name: 'completed',
        literalName: t(`tabs.completed`),
        content: <ResolvedPetitions />,
      },
    ],
    [t],
  );

  return (
    <>
      <TopBar title={text.title} />
      <Card className="p-4 mx-3">
        <h6 className="fs-20 text-uppercase fw-700 tc-primary ">
          {text.subtitle}
        </h6>
        <p className="fs-18 text-light mb-5 mt-3">{text.info}</p>
        <TabControl tabs={tabs} path={PathsLayouts.petitions} />
      </Card>
    </>
  );
}
